.navigation {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	width: 25vw;
	min-width: 500px;
	margin-top: 2.5rem;
}

.navigation .item {
	/* width: 100%; */
}

.m-rep {
	text-align: center;
}

@media (max-width: 420px) {
	.navigation {
		flex-direction: column;
	}

	.navigation .item {
		padding: 20px 0;
		display: block;
		width: 100%;
		text-align: center;
	}
}

/*
display: "flex",
justifyContent: "space-between",
alignItems: "center",
width: "25vw",
minWidth: "500px",
marginTop: "2.5rem"
*/